// autogenerated
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {BoatType, BookingType, PaymentStatus, BookingStatus} from "@/models/Enums";
import ServiceProvider from "@/models/server/ServiceProvider";
import User from "@/models/server/User";
import Waypoint from "@/models/server/bout/Waypoint";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import CruisePackagePoint from "@/models/server/cruise/CruisePackagePoint";
import BoatPrice from "@/models/server/bout/BoatPrice";
import Boat from "@/models/server/bout/Boat";
import Area from "@/models/server/bout/Area";
import BookingStatusModel from "@/models/server/bout/BookingStatusModel";
import BaseBooking from "@/models/server/bout/BaseBooking";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

export default class Booking extends BaseBooking {

    public id: string = "";

    public serviceProviderId: string | null = null;

    public serviceProvider: ServiceProvider | null = null;

    public duration: number = 0;

    public estimatedMinPrice: number = 0;

    public estimatedMaxPrice: number = 0;

    public moneySymbol: string | null = null;

    public price: number | null = null;

    public passengerPrice: number | null = null;

    public balancedPassengerPrice: number | null = null;

    public companyShare: number | null = null;

    public captainShare: number | null = null;

    public balancedCaptainShare: number | null = null;

    public captainMargin: number | null = null;

    public stripeCountryCode: string | null = null;

    public pricePerLiter: number = 0;

    public boatFuelConsumption: number | null = null;

    public totalFuelConsumption: number | null = null;

    public stripeShare: number | null = null;

    public platformShare: number | null = null;

    public cancelMargin: number | null = null;

    public cancelHours: number | null = null;

    public cancelPrice: number | null = null;

    public timeInMinutes: number | null = null;

    public netTimeInMinutes: number | null = null;

    public estimatedMinTimeInMinutes: number = 0;

    public estimatedMaxTimeInMinutes: number = 0;

    public actualTimeInMinutes: number | null = null;

    public distance: number | null = null;

    public actualDistance: number | null = null;

    public netDistance: number = 0;

    public estimatedDistance: number = 0;

    public captainRatingPassenger: number | null = null;

    public passengerRatingBoat: number | null = null;

    public passengerRatingCaptain: number | null = null;

    public minutePrice: number | null = null;

    public captainNotAssignedNotification: number = 0;

    public shuttleId: string | null = null;

    public ticketCode: string | null = null;

    public noShow: boolean | null = null;

    public noShowAt: Date | null = null;

    public checkedIn: boolean | null = null;

    public checkedInAt: Date | null = null;

    public checkedOut: boolean | null = null;

    public checkedOutAt: Date | null = null;

    public noProperCheckOut: boolean | null = null;

    public ticketQrCodeCodeHtmlImage: string | null = null;

    public boatType: BoatType = BoatType.Small;

    public bookingType: BookingType = BookingType.Ride;

    public paymentStatus: PaymentStatus = PaymentStatus.None;

    public latestStatus: BookingStatus = BookingStatus.New;

    public captainId: string | null = null;

    public captain: User | null = null;

    public passengerId: string = "";

    public passenger: User | null = null;

    public waypointId: string | null = null;

    public waypoint: Waypoint | null = null;

    public customPickupLocation: boolean = false;

    public customPickupLocationAddress: string | null = null;

    public cruisePackageId: string | null = null;

    public cruisePackage: CruisePackage | null = null;

    public cruisePackagePointId: string | null = null;

    public cruisePackagePoint: CruisePackagePoint | null = null;

    public boatPrice: BoatPrice | null = null;

    public boatPriceId: string | null = null;

    public createdAt: Date = new Date();

    public tripStartedAt: Date | null = null;

    public tripEndAt: Date | null = null;

    public departureAt: Date | null = null;

    public arrivalAt: Date | null = null;

    public stopsCount: number | null = null;

    public passengers: number = 0;

    public children: number = 0;

    public bikesAndTrolleys: number = 0;

    public pensioners: number = 0;

    public boatId: string | null = null;

    public boat: Boat | null = null;

    public areaId: string = "";

    public area: Area | null = null;

    public statuses: BookingStatusModel[] | null = null;

    public shuttleRoutes: string[] | null = null;

    public readonly isBooking: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof Booking) || ((from as any).isBooking === true));
    }

    public static as(from?: object | null): Booking | null {
        return (Booking.is(from)) ? from as Booking : null;
    }

    // #region Placeholder

    public static getStatusColorStyle(booking: Booking): string {
        if (Booking.captainNotFound(booking)) {
            return "booking-red";
        }
        
        switch (booking.latestStatus) {
            case BookingStatus.New:
                return "booking-blue";
            case BookingStatus.CompletedByCaptain:
            case BookingStatus.AcceptedByCaptain:
                return "booking-green";
            case BookingStatus.StartByCaptain:
                return "booking-yellow";
            case BookingStatus.CancelledByCaptain:
            case BookingStatus.CancelledByPassenger:
                return "booking-red";
            default:
                return "booking-black";
        }
    }

    public static expired(booking: Booking, expiredTripCanBeStartedAfterInHours: number | null = null): boolean {
        if (booking.bookingTime.inPast()) {
            if (booking.latestStatus == BookingStatus.New) {
                return true;
            }
            if (booking.latestStatus == BookingStatus.AcceptedByCaptain) {
                if (!!expiredTripCanBeStartedAfterInHours) {
                    const startTime: Date = booking.bookingTime.addHours(expiredTripCanBeStartedAfterInHours);
                    return startTime.inPast();
                }
                return true;
            }
        }
        return false;
    }
    
    public static completed(booking: Booking): boolean {
        const statuses: BookingStatus[] = [BookingStatus.CompletedByCaptain, BookingStatus.CancelledByCaptain, BookingStatus.CancelledByPassenger];
        const expiredTripCanBeStartedAfterInHours: number = AppController.settings.expiredTripCanBeStartedAfterInHours;
        
        return (
            (statuses.includes(booking.latestStatus)) || 
            (Booking.expired(booking, expiredTripCanBeStartedAfterInHours))
        );
    }

    public static captainNotFound(booking: Booking): boolean {
        return (
            ((booking.latestStatus == BookingStatus.New) && (Booking.expired(booking))) ||
            ((booking.latestStatus == BookingStatus.CancelledByCaptain) && (booking.captainId == null))
        );
    }
    
    public static getCountryLocalTime(booking: BaseBooking, value: Date): Date {
        const bookingTimeOffset: number | null = booking.bookingTimeOffset;
        if (bookingTimeOffset != null) {
            const timezoneOffset: number = AppController.timezoneOffset;
            if (bookingTimeOffset != timezoneOffset) {
                const offset: number = bookingTimeOffset - timezoneOffset;
                value = Utility.addMinutes(value, offset);
            }
        }
        return value;
    }
    
    public static isRemoteBooking(booking: BaseBooking): boolean {
        return (booking.bookingTimeOffset != AppController.timezoneOffset);
    }
    
    public static generateGoogleMapsLink(booking: Booking | null | undefined): string | null {
        return Waypoint.generateGoogleMapsLink(booking?.waypoint);
    }
    
    public static getStatusText(booking: Booking): string {
        return (Booking.captainNotFound(booking))
            ? Localizer.bookingStatusCaptainNotFound
            : booking.latestStatus.format(nameof(BookingStatus))
    }

    // #endregion
}