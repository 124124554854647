// autogenerated
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import {LocalizationString} from "@reapptor-apps/reapptor-react-common";
import {ApplicationType, PaymentPolicy} from "@/models/Enums";
import FirebaseSettings from "@/models/server/serviceproviderinfo/FirebaseSettings";
import AndroidAssetsSettings from "@/models/server/serviceproviderinfo/AndroidAssetsSettings";
import SocialMediaLinks from "@/models/server/serviceproviderinfo/SocialMediaLinks";
import StripeKycStatus from "@/models/server/stripe/kycstatus/StripeKycStatus";
import BankAccount from "@/models/server/bout/BankAccount";

export default class ServiceProvider {

    public id: string = "";

    public parentId: string | null = null;

    public parent: ServiceProvider | null = null;

    public companyName: string | null = null;

    public companyBusinessId: string | null = null;

    public logo: string = "";

    public favicon: string | null = null;

    public url: string | null = null;

    public whiteLabeling: boolean = false;

    public applicationName: string | null = null;

    public companyShortName: string | null = null;

    public emailSender: string | null = null;

    public contactEmail: string | null = null;

    public emailServerApiKey: string | null = null;

    public emailServerApiUrl: string | null = null;

    public applicationUrl: string | null = null;

    public smsSenderShortName: string | null = null;

    public smsSenderPhoneNumber: string | null = null;

    public firebaseSettings: FirebaseSettings | null = null;

    public androidAssetsSettings: AndroidAssetsSettings | null = null;

    public socialMediaLinks: SocialMediaLinks | null = null;

    public companyAddress: GeoLocation | null = null;

    public companyAddressId: string | null = null;

    public isVirtualCompanyAddress: boolean = false;

    public companyVirtualAddress: string | null = null;

    public slug: string | null = null;

    public applicationTypes: ApplicationType[] = [];

    public language: string | null = null;

    public location: GeoLocation | null = null;

    public locationId: string | null = null;

    public name: LocalizationString | null = null;

    public description: LocalizationString | null = null;

    public countryIds: string[] | null = null;

    public areaIds: string[] | null = null;

    public waypointIds: string[] | null = null;

    public supportsRating: boolean = false;

    public paymentPolicy: PaymentPolicy = PaymentPolicy.CompletedByCaptain;

    public noRefund: boolean = false;

    public personalInformationWarning: boolean = false;

    public shuttleMaxTickets: number | null = null;

    public minBookingDepthInHours: number | null = null;

    public maxBookingDepthInHours: number | null = null;

    public minBookingRangeInMinutes: number | null = null;

    public maxBookingRangeInMinutes: number | null = null;

    public estimatedBookingExpirationInMinutes: number | null = null;

    public waterOperator: boolean = false;

    public passPaymentToParent: boolean | null = null;

    public stripeEmail: string | null = null;

    public stripeKycVerified: boolean = false;

    public stripeAccountId: string | null = null;

    public stripeCustomerId: string | null = null;

    public margin: number | null = null;

    public landingPage: boolean | null = null;

    public androidAppAvailable: boolean | null = null;

    public appleAppAvailable: boolean | null = null;

    public passengerSignUpAvailable: boolean | null = null;

    public captainSignUpAvailable: boolean | null = null;

    public failedPaymentEmail: boolean | null = null;

    public stripeKycStatus: StripeKycStatus | null = null;

    public bankAccount: BankAccount | null = null;

    public readonly isServiceProvider: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ServiceProvider) || ((from as any).isServiceProvider === true));
    }

    public static as(from?: object | null): ServiceProvider | null {
        return (ServiceProvider.is(from)) ? from as ServiceProvider : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}