import React from "react";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {
    BaseComponent,
    ch,
    IBaseComponentProps,
    LocalizationString,
    PageRoute,
    PageRouteProvider, ReactUtility
} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType} from "@reapptor-apps/reapptor-react-components";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import HeaderInfo from "@/pages/Mobile/BookingDetails/BookingInfo/HeaderInfo/HeaderInfo";
import DateInfo from "@/pages/Mobile/BookingDetails/BookingInfo/DateInfo/DateInfo";
import WaypointInfo from "@/pages/Mobile/BookingDetails/BookingInfo/WaypointInfo/WaypointInfo";
import PriceInfo from "@/pages/Mobile/BookingDetails/BookingInfo/PriceInfo/PriceInfo";
import NotificationInfo from "@/pages/Mobile/BookingDetails/BookingInfo/NotificationInfo/NotificationInfo";
import BookingTicketModal from "@/pages/Mobile/BookingDetails/BookingInfo/BookingTicketModal/BookingTicketModal";
import Booking from "@/models/server/bout/Booking";
import {BookingStatus, BookingType, PaymentStatus, PostTripEmailType} from "@/models/Enums";
import SendPostTripEmailRequest from "../../../../models/server/requests/SendPostTripEmailRequest";
import GetBookingReceiptPdfRequest from "@/models/server/requests/GetBookingReceiptPdfRequest";
import {CalendarEvent} from "@/models/server/CalendarEvent";
import PageDefinitions from "@/providers/PageDefinitions";
import ValidateEstimatedBookingResponse from "@/models/server/responses/ValidateEstimatedBookingResponse";
import ValidateEstimatedBookingRequest from "@/models/server/requests/ValidateEstimatedBookingRequest";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import PassengersInfo from "@/pages/Mobile/BookingDetails/BookingInfo/PassengersInfo/PassengersInfo";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import ShuttleEstimatedBooking from "@/models/server/shuttle/ShuttleEstimatedBooking";
import User from "@/models/server/User";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./BookingInfo.module.scss";

export interface IBookingInfoProps extends IBaseComponentProps {
    item: EstimatedBooking | Booking | CruisePackageBooking;
    readonly?: boolean;
    previewHasOnlyInfo?: boolean;
    onChange?: (sender: BookingInfo) => Promise<void>;
    requestTrip?: (sender: BookingInfo, cruisePackageBooking: CruisePackageBooking) => Promise<void>;
    book?: (sender: BookingInfo, booking: EstimatedBooking | CruisePackageBooking) => Promise<void>;
    close?: (sender: BookingInfo) => Promise<void>;
    setCaptain?: (sender: BookingInfo, booking: Booking) => Promise<void>;
    declineTrip?: (sender: BookingInfo, booking: Booking) => Promise<void>;
    approveTrip?: (sender: BookingInfo, booking: EstimatedBooking | CruisePackageBooking) => Promise<void>;
    cancelTrip?: (sender: BookingInfo, booking: EstimatedBooking | Booking | CruisePackageBooking) => Promise<void>;
    startTrip?: (sender: BookingInfo, booking: Booking) => Promise<void>;
    completeTrip?: (sender: BookingInfo, booking: Booking) => Promise<void>;
    payTrip?: (sender: BookingInfo, booking: Booking) => Promise<void>;
    checkIn?: (sender: BookingInfo, booking: Booking) => Promise<void>;
    noShow?: (sender: BookingInfo, booking: Booking) => Promise<void>;
}

interface IBookingInfoState {
}

export default class BookingInfo extends BaseComponent<IBookingInfoProps, IBookingInfoState> {

    state: IBookingInfoState = {};

    private readonly _bookingTicketModelRef: React.RefObject<BookingTicketModal> = React.createRef();

    public async onChangeAsync(): Promise<void> {
        if (this.props.onChange) {
            await this.props.onChange(this);
        }
    }

    public async requestTripAsync(): Promise<void> {
        if (this.props.requestTrip) {
            if (this.cruisePackageBooking != null) {
                await this.props.requestTrip(this, this.cruisePackageBooking);
            }
        }
    }

    public async openTicketAsync(): Promise<void> {
        if ((this._bookingTicketModelRef.current) && (this.booking)) {
            await this._bookingTicketModelRef.current?.openAsync(this.booking.id);
        }
    }

    public async bookAsync(): Promise<void> {
        if ((this.props.book) && ((this.estimatedBooking) || (this.cruisePackageBooking))) {

            const request = new ValidateEstimatedBookingRequest();
            request.booking = this.estimatedBooking;
            request.cruisePackageBooking = this.cruisePackageBooking;
            request.shuttleBooking = this.shuttleEstimatedBooking;
            request.bookingTime = this.item.bookingTime;

            const response: ValidateEstimatedBookingResponse = await this.postAsync("/api/mobileApp/validateEstimatedBooking", request);

            if (response.success) {

                const booking: EstimatedBooking | CruisePackageBooking = (this.estimatedBooking ?? this.cruisePackageBooking)!;

                await this.props.book(this, booking);

            } else {

                const warning: string = (response.creditCardIsMissing)
                    ? Localizer.mobileBookingDetailsPageAlertErrorCreditCardIsMissing
                    : (response.hasPendingPayments)
                        ? Localizer.mobileBookingDetailsPageAlertErrorHasPendingPayments
                        : (response.inPast)
                            ? Localizer.mobileBookingDetailsPageAlertErrorInPast
                            : Localizer.mobileBookingDetailsPageAlertErrorSomethingWentWrong;

                if (response.creditCardIsMissing) {

                    const route: PageRoute = PageDefinitions.editCreditCard(null, true, true);

                    await ch.alertWarningAsync(warning, false, false, null, route);

                } else {

                    await ch.alertWarningAsync(warning, false, false);

                }

            }
        }
    }

    public async setCaptainAsync(): Promise<void> {
        if ((this.props.setCaptain) && (this.booking) && (this.setCaptainEnabled)) {
            await this.props.setCaptain(this, this.booking);

            await this.createAppleCalendarEventAsync();
        }
    }

    public async declineTripAsync(): Promise<void> {
        if ((this.props.declineTrip) && (this.booking)) {
            await this.props.declineTrip(this, this.booking);
        }
    }

    public async approveTripAsync(): Promise<void> {
        if ((this.props.approveTrip) && ((this.estimatedBooking) || (this.isCruisePackageBooking))) {
            const booking: EstimatedBooking | CruisePackageBooking = (this.estimatedBooking ?? this.cruisePackageBooking)!;
            await this.props.approveTrip(this, booking);
        }
    }

    public async cancelTripAsync(): Promise<void> {
        if ((this.props.cancelTrip) && ((this.estimatedBooking) || (this.booking) || (this.cruisePackageBooking))) {
            await this.props.cancelTrip(this, (this.estimatedBooking ?? this.booking ?? this.cruisePackageBooking)!);
        }
    }

    public async checkInPassengerAsync(): Promise<void> {
        if ((this.props.checkIn) && (this.booking)) {
            await this.props.checkIn(this, this.booking);
        }
    }

    public async passengerNoShowAsync(): Promise<void> {
        if ((this.props.noShow) && (this.booking)) {
            await this.props.noShow(this, this.booking);
        }
    }

    public async startTripAsync(): Promise<void> {
        if ((this.props.startTrip) && (this.booking)) {
            await this.props.startTrip(this, this.booking);
        }
    }

    public async completeTripAsync(): Promise<void> {
        if ((this.props.completeTrip) && (this.booking)) {
            await this.props.completeTrip(this, this.booking);
        }
    }

    public async payTripAsync(): Promise<void> {
        if ((this.props.payTrip) && (this.booking)) {
            await this.props.payTrip(this, this.booking);
        }
    }

    public async sendPostTripEmailAsync(sender: Button, data: string | null, emailType: PostTripEmailType): Promise<void> {
        if (data) {
            const request = new SendPostTripEmailRequest();
            request.bookingId = this.booking!.id
            request.postTripEmailType = emailType;
            request.content = data;

            await this.postAsync("/api/mobileApp/sendPostTripEmail", request);
        }
    }

    public async downloadReceiptPdfAsync(sender: Button): Promise<void> {
        if (this.booking) {
            const request = new GetBookingReceiptPdfRequest();

            request.bookingId = this.booking.id;
            request.asCaptain = this.asCaptain;

            const pdf: FileModel = await sender.postAsync("/api/mobileApp/getBookingReceiptPdf", request);

            ch.download(pdf);
        }
    }

    public async createAppleCalendarEventAsync(): Promise<void> {
        if ((this.props.setCaptain) && (this.booking)) {
            const webKit: any | null = (window as any).webkit;
            if (webKit) {
                const messageHandlers: any | null = webKit.messageHandlers;

                if (messageHandlers && messageHandlers.postMessageListener && typeof messageHandlers.postMessageListener.postMessage === "function") {
                    const event = new CalendarEvent();
                    event.title = Localizer.mobileBookingInfoCalendarEventBoutRide;
                    event.startDate = this.booking.bookingTime;
                    event.endDate = this.booking.bookingTime.addHours(1);
                    event.notes = Localizer.mobileBookingInfoCalendarEventBoutRideFromTo.format(this.booking.waypoint?.source, this.booking.waypoint?.destination);

                    try {
                        messageHandlers.postMessageListener.postMessage(JSON.stringify(event));
                    } catch (e) {
                        await PageRouteProvider.exception(e as Error);
                    }
                }
            }
        }
    }

    public get item(): EstimatedBooking | Booking | CruisePackageBooking {
        return this.props.item;
    }

    private get isEstimatedBooking(): boolean {
        return (EstimatedBooking.is(this.item));
    }

    private get isShuttleEstimatedBooking(): boolean {
        return (ShuttleEstimatedBooking.is(this.item));
    }

    private get isShuttleBooking(): boolean {
        return (this.booking?.bookingType == BookingType.Shuttle);
    }

    private get isBooking(): boolean {
        return (Booking.is(this.item));
    }

    private get isCruisePackageRide(): boolean {
        return (this.booking != null) && (!!this.booking.cruisePackageId);
    }

    private get isCruisePackageBooking(): boolean {
        return (CruisePackageBooking.is(this.item));
    }

    private get supportsNotification(): boolean {
        return (
            (this.isEstimatedBooking) &&
            (!this.isShuttleEstimatedBooking) &&
            (!this.isCruisePackageRide) &&
            (!ServiceProviderController.hasEstimatedBookingExpirationInMinutes)
        );
    }

    private get isMyBooking(): boolean {
        const userId: string = ch.getUserId();
        return ((this.booking != null) && ((this.booking.captainId == userId) || (this.booking.passengerId == userId)));
    }

    private get estimatedBooking(): EstimatedBooking | null {
        return EstimatedBooking.as(this.item);
    }

    private get shuttleEstimatedBooking(): ShuttleEstimatedBooking | null {
        return ShuttleEstimatedBooking.as(this.item);
    }

    private get booking(): Booking | null {
        return Booking.as(this.item);
    }

    private get cruisePackageBooking(): CruisePackageBooking | null {
        return CruisePackageBooking.as(this.item);
    }

    private get cruisePackage(): CruisePackage | null {
        return this.cruisePackageBooking?.cruisePackage ?? this.booking?.cruisePackage ?? null;
    }

    private get hasReceipt(): boolean {
        return (this.booking != null) &&
            (
                (this.completed) ||
                (
                    (this.cancelled && !!this.booking.cancelPrice) &&
                    (
                        (this.booking.latestStatus == BookingStatus.CancelledByPassenger && this.asPassenger) ||
                        (this.booking.latestStatus == BookingStatus.CancelledByCaptain && this.asCaptain)
                    )
                ) ||
                (this.isShuttleBooking)
            );
    }

    private get expired(): boolean {
        return (this.booking != null) && (Booking.expired(this.booking, AppController.settings.expiredTripCanBeStartedAfterInHours));
    }

    private get cancelled(): boolean {
        const canceled: BookingStatus[] = [BookingStatus.CancelledByPassenger, BookingStatus.CancelledByCaptain];
        return (this.booking != null) && (canceled.includes(this.booking.latestStatus));
    }

    private get completed(): boolean {
        return (this.booking != null) && (this.booking.latestStatus == BookingStatus.CompletedByCaptain);
    }

    private get pending(): boolean {
        return (this.booking != null) && (this.booking.paymentStatus == PaymentStatus.Pending);
    }

    private get ongoingTrip(): boolean {
        return (this.booking?.latestStatus == BookingStatus.StartByCaptain);
    }

    private get passengerOnBoard(): boolean {
        return (this.booking?.checkedIn == true);
    }

    private get newTrip(): boolean {
        return (this.booking?.latestStatus == BookingStatus.New);
    }

    private get acceptedByCaptain(): boolean {
        return (this.booking?.latestStatus == BookingStatus.AcceptedByCaptain);
    }

    private get hasCaptain(): boolean {
        return (!!this.booking?.captainId);
    }

    private get hasBoat(): boolean {
        return (!!this.booking?.boatId);
    }

    private get captainPhone(): string {
        return (this.booking?.captain?.phone || "");
    }

    private get passenger(): User | null {
        return (this.cruisePackageBooking?.passenger) ?? (this.booking?.passenger) ?? null;
    }

    private get canSetCaptain(): boolean {
        return (
            (this.isBooking) && (this.editable) && (!this.expired) &&
            (this.asCaptain) && (!this.hasCaptain) && (this.hasBoat)
        );
    }

    private get canCancelTrip(): boolean {
        return (
            (this.editable) &&
            (this.isMyBooking) &&
            (
                (this.isEstimatedBooking) ||
                (this.newTrip) ||
                (this.acceptedByCaptain)
            )
        );
    }

    private get canDeclineTrip(): boolean {
        return (
            (this.isBooking) && (this.editable) && (this.asCaptain) &&
            (!this.hasCaptain) && (this.hasBoat)
        );
    }

    private get canCallToCaptain(): boolean {
        return (
            (this.isMyBooking) && (!!this.captainPhone) && (!this.isShuttleBooking) &&
            ((!this.completed) || (ServiceProviderController.canCallToCaptainForCompletedTrip))
        );
    }

    private get passengerPhone(): string {
        return (this.booking?.passenger?.phone || "");
    }

    private get asCaptain(): boolean {
        return (AppController.asCaptain);
    }

    private get asPassenger(): boolean {
        return (AppController.asPassenger);
    }

    public get readonly(): boolean {
        return ((this.props.readonly === true) || (this.cancelled) || (this.completed) || (this.expired));
    }
    
    public get dateReadonly(): boolean {
        return ((this.readonly) || (this.isShuttleEstimatedBooking));
    }

    public get previewHasOnlyInfo(): boolean {
        return (this.props.previewHasOnlyInfo == true);
    }

    public get editable(): boolean {
        return (!this.readonly);
    }

    public get hasTicket(): boolean {
        return ((this.booking != null) && (!!this.booking.ticketCode));
    }

    public get setCaptainEnabled(): boolean {
        return ((this.passenger != null) && (this.passenger.hasValidPaymentMethod));
    }

    public get valid(): boolean {
        return (
            (this.item != null) &&
            (this.item.bookingTime.inFuture()) &&
            ((!this.cruisePackageBooking) || (CruisePackageBooking.valid(this.cruisePackageBooking)))
        );
    }
    
    public async initializeAsync(): Promise<void> {
        if ((this.estimatedBooking) && (!this.supportsNotification)) {
            this.estimatedBooking.captainNotAssignedNotification = 0;
        }
    }

    public renderAuthenticatedButtons(): React.ReactNode {
        
        return (
            <React.Fragment>

                {
                    (((this.isEstimatedBooking) || (this.isCruisePackageBooking)) && (!this.isShuttleEstimatedBooking) && (this.editable)) &&
                    (
                        <Button id="book" block
                                className={styles.book}
                                label={Localizer.mobileBookingInfoButtonBookLabel}
                                icon={{name: "fal anchor"}}
                                type={ButtonType.Dark}
                                right={false}
                                disabled={!this.valid}
                                onClick={() => this.bookAsync()}
                        />
                    )
                }

                {
                    ((this.isShuttleEstimatedBooking) && (this.editable)) &&
                    (
                        <Button id="buyTicket" block
                                label={Localizer.mobileBookingInfoButtonBuyTicketLabel}
                                icon={{name: "fal anchor"}}
                                type={ButtonType.Dark}
                                right={false}
                                disabled={!this.valid}
                                onClick={() => this.bookAsync()}
                        />
                    )
                }

                {
                    (this.hasTicket) &&
                    (
                        <Button id="openTicket" block
                                label={Localizer.mobileBookingInfoButtonOpenTicketLabel}
                                icon={"fal fa-qrcode"}
                                type={ButtonType.Dark}
                                right={false}
                                onClick={() => this.openTicketAsync()}
                        />
                    )
                }

                {
                    ((this.isMyBooking) && (this.hasReceipt) && (!AppController.isIosNative)) &&
                    (
                        <Button id="receipt" block
                                label={Localizer.mobileBookingInfoButtonDownloadReceiptLabel}
                                icon={{name: "fal fa-download"}}
                                type={ButtonType.Primary}
                                right={false}
                                onClick={(sender: Button) => this.downloadReceiptPdfAsync(sender)}
                        />
                    )
                }

                {
                    (this.canSetCaptain) &&
                    (
                        <Button id="setCaptain" block
                                label={Localizer.mobileBookingInfoButtonSetCaptainLabel}
                                icon={{name: "fal check"}}
                                type={ButtonType.Dark}
                                right={false}
                                disabled={!this.setCaptainEnabled}
                                onClick={() => this.setCaptainAsync()}
                        />
                    )
                }

                {
                    (this.canDeclineTrip) &&
                    (
                        <Button id="decline" block
                                label={Localizer.mobileBookingInfoButtonDeclineLabel}
                                icon={"fal ban"}
                                type={ButtonType.Dark}
                                right={false}
                                onClick={() => this.declineTripAsync()}
                        />
                    )
                }

                {
                    // ((this.isMyBooking) && (this.asPassenger) && (this.readonly) && (this.newTrip)) &&
                    (((this.isEstimatedBooking) || (this.isCruisePackageBooking)) && (this.readonly)) &&
                    (
                        <Button id="approve" block
                                className={styles.approve}
                                label={Localizer.genericApprove}
                                icon={{name: "fal thumbs-up"}}
                                type={ButtonType.Dark}
                                right={false}
                                onClick={() => this.approveTripAsync()}
                        />
                    )
                }

                {
                    ((this.isMyBooking) && (this.editable) && (this.hasCaptain) && (this.asCaptain) && (!this.ongoingTrip) && (!this.expired)) &&
                    (
                        <Button id="startTrip" block
                                label={Localizer.mobileBookingInfoButtonStartTripLabel}
                                icon={{name: "fal play"}}
                                type={ButtonType.Dark}
                                right={false}
                                onClick={() => this.startTripAsync()}
                        />
                    )
                }

                {
                    (this.canCancelTrip) &&
                    (
                        <Button id="cancelTrip" block
                                className={styles.cancelTrip}
                                label={Localizer.mobileBookingInfoButtonCancelTripLabel}
                                icon={"fal ban"}
                                type={ButtonType.Primary}
                                right={false}
                                onClick={() => this.cancelTripAsync()}
                        />
                    )
                }

                {
                    ((this.asCaptain) && (this.isMyBooking) && (this.ongoingTrip) && (!this.passengerOnBoard)) &&
                    (
                        <Button id="checkInPassenger" block
                                label={Localizer.mobileBookingInfoButtonCheckInPassengerLabel}
                                icon={{name: "fal check"}}
                                type={ButtonType.Primary}
                                right={false}
                                onClick={() => this.checkInPassengerAsync()}
                        />
                    )
                }
                
                {
                    ((this.isMyBooking) && (this.editable) && (this.hasCaptain) && (this.asCaptain) && (this.ongoingTrip) && (this.passengerOnBoard)) &&
                    (
                        <Button id="completeTrip" block
                                label={Localizer.mobileBookingInfoButtonCompleteTripLabel}
                                icon={{name: "fal flag-checkered"}}
                                type={ButtonType.Dark}
                                right={false}
                                onClick={() => this.completeTripAsync()}
                        />
                    )
                }

                {
                    ((this.isMyBooking) && (this.asPassenger) && (this.completed) && (this.pending)) &&
                    (
                        <Button id="payTrip" block
                                label={Localizer.mobileBookingInfoButtonPayTripLabel}
                                icon={{name: "fas fa-dollar-sign"}}
                                type={ButtonType.Primary}
                                right={false}
                                onClick={() => this.payTripAsync()}
                        />
                    )
                }

                {
                    (this.asPassenger) &&
                    (
                        <>
                            {
                                (this.canCallToCaptain) &&
                                (
                                    <Button id="callToCaptain" block
                                            label={Localizer.mobileBookingInfoButtonCallToCaptainLabel}
                                            icon={{name: "fas fa-phone"}}
                                            type={ButtonType.Primary}
                                            right={false}
                                            onClick={async () => ch.callTo(this.captainPhone)}
                                    />
                                )
                            }

                            {
                                ((this.isMyBooking) && (this.completed)) &&
                                (
                                    <Button id="forgot" block
                                            label={Localizer.mobileBookingInfoButtonForgotSomethingLabel}
                                            confirm={{
                                                title: Localizer.mobileBookingInfoButtonForgotSomethingConfirm,
                                                comment: true,
                                                minLength: 10,
                                                placeholder: Localizer.mobileBookingInfoButtonForgotSomethingPlace
                                            }}
                                            icon={{name: "fal fa-lightbulb-exclamation"}}
                                            type={ButtonType.Primary}
                                            right={false}
                                            onClick={(sender: Button, data: string | null) => this.sendPostTripEmailAsync(sender, data, PostTripEmailType.ForgotSomething)}
                                    />
                                )
                            }

                            {
                                ((this.isMyBooking) && (this.readonly) && (!this.newTrip)) &&
                                (
                                    <Button id="complain" block
                                            label={Localizer.mobileBookingInfoButtonSomethingWrongLabel}
                                            icon={{name: "fal fa-comments"}}
                                            confirm={{
                                                title: Localizer.mobileBookingInfoButtonSomethingWrongConfirm,
                                                comment: true,
                                                minLength: 10,
                                                placeholder: Localizer.mobileBookingInfoButtonSomethingWrongPlace
                                            }}
                                            type={ButtonType.Primary}
                                            right={false}
                                            onClick={(sender: Button, data: string | null) => this.sendPostTripEmailAsync(sender, data, PostTripEmailType.Complain)}
                                    />
                                )
                            }

                        </>
                    )
                }

                {
                    ((this.isMyBooking) && (this.asCaptain) && (!!this.passengerPhone) && (!this.isShuttleBooking)) &&
                    (
                        <Button id="callToPassenger" block
                                label={Localizer.mobileBookingInfoButtonCallToPassengerLabel}
                                icon={{name: "fas fa-phone"}}
                                type={ButtonType.Primary}
                                right={false}
                                onClick={async () => ch.callTo(this.passengerPhone)}
                        />
                    )
                }

                {
                    ((this.asCaptain) && (this.isMyBooking) && (this.ongoingTrip) && (!this.passengerOnBoard)) &&
                    (
                        <Button id="passengerNoShow" block
                                label={Localizer.mobileBookingInfoButtonPassengerNoShowLabel}
                                confirm={Localizer.mobileBookingInfoButtonPassengerNoShowConfirm}
                                icon={"fal ban"}
                                type={ButtonType.Dark}
                                right={false}
                                onClick={() => this.passengerNoShowAsync()}
                        />
                    )
                }

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {

        return (
            <div id={this.id} className={this.css(styles.bookingInfo, this.props.className)}>

                {
                    (this.readonly) &&
                    (
                        <span className={styles.title}>
                            {
                                (this.cruisePackage != null)
                                    ? LocalizationString.value(this.cruisePackage.name)
                                    : Localizer.mobileBookingInfoBookingInformationSpan
                            }
                        </span>
                    )
                }

                <HeaderInfo previewHasOnlyInfo={this.previewHasOnlyInfo}
                            booking={this.item}
                />

                <DateInfo readonly={this.dateReadonly}
                          booking={this.item}
                          onChange={() => this.onChangeAsync()}
                />

                {
                    (this.cruisePackageBooking) &&
                    (
                        <PassengersInfo readonly={this.readonly}
                                        booking={this.cruisePackageBooking}
                                        onChange={() => this.onChangeAsync()}
                        />
                    )
                }

                <WaypointInfo readonly={this.readonly}
                              booking={this.item}
                              onChange={() => this.onChangeAsync()}
                />

                <PriceInfo booking={this.item}/>

                {
                    ((this.supportsNotification) && (this.editable) && (this.asPassenger)) &&
                    (
                        <NotificationInfo booking={this.item as EstimatedBooking | Booking}
                                          onChange={() => this.onChangeAsync()}
                        />
                    )
                }

                {
                    ((this.isShuttleEstimatedBooking) && (ServiceProviderController.isHds)) &&
                    (
                        <div className={styles.marketingText}>

                            {ReactUtility.toMultiLines(Localizer.bookingInfoPageMarketingText)}
                            
                        </div>
                    )
                }

                <div className={styles.expander}/>

                <ButtonContainer className={styles.buttons}>

                    {
                        ((!this.isAuthenticated) && (this.isCruisePackageBooking) && (this.editable)) &&
                        (
                            <Button id="requestTrip" block
                                    label={Localizer.mobileBookingInfoButtonRequestTripLabel}
                                    icon={{name: "fal anchor"}}
                                    type={ButtonType.Dark}
                                    right={false}
                                    disabled={!this.valid}
                                    onClick={() => this.requestTripAsync()}
                            />
                        )
                    }

                    {
                        (this.isAuthenticated) &&
                        (
                            this.renderAuthenticatedButtons()
                        )
                    }

                </ButtonContainer>

                <BookingTicketModal ref={this._bookingTicketModelRef}/>

            </div>
        );
    }
}